import React from 'react';
import { Box, CardMedia } from '@mui/material';

const NewsImage = ({ image, category }) => (
  <Box sx={{ position: 'relative' }}>
    <CardMedia
      component="img"
      sx={{ height: 150, objectFit: 'cover' }}
      image={image || 'https://via.placeholder.com/150'}
      alt="News image"
    />
    <Box
      sx={{
        position: 'absolute',
        bottom: 10,
        right: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        padding: '4px 8px',
        fontSize: '0.75rem',
        fontWeight: 'bold',
      }}
    >
      {category.name}
    </Box>
  </Box>
);

export default NewsImage;
