import React from 'react';
import { Tabs, Tab } from '@mui/material';

const AuthTabs = ({ activeTab, handleChangeTab }) => (
  <Tabs value={activeTab} onChange={handleChangeTab} centered>
    <Tab label="Вход" sx={{ fontSize: '14px' }} />
    <Tab label="Регистрация" sx={{ fontSize: '14px' }} />
  </Tabs>
);

export default AuthTabs;
