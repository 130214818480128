import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import api from '../../api';
import NewsCard from '../NewsCard';
import { useTheme } from "@mui/material/styles";


const UserRecommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await api.get('/api/v1/stories/recommendations/user-recommendations/');
        setRecommendations(response.data);
      } catch (error) {
        console.error('Ошибка при получении рекомендаций:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Рекомендации для вас
      </Typography>
      <Grid container spacing={2}>
        {recommendations.map((story) => (
          <Grid item xs={12} sm={6} md={4} key={story.id}>
            <NewsCard item={story.news_article} time={story.creation_at} theme={theme}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UserRecommendations;
