import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container } from '@mui/material';
import CategorySlider from '../components/CategoryPage/CategorySlider';
import NewsSection from '../components/NewsSection';
import api from '../api';
import { useParams } from 'react-router-dom';

const CategoriesPage = () => {
  const { id } = useParams(); 
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(id || 1);

  useEffect(() => {
    api.get('/api/v1/categories/')
      .then(response => {
        setCategories(response.data);
        // Установка первой категории только если selectedCategory не установлен
        if (!id && response.data.length > 0) {
          setSelectedCategory(response.data[0].id);
        }
      })
      .catch(error => console.error('Ошибка при загрузке категорий:', error));
  }, [id]); // Добавлен массив зависимостей, чтобы useEffect вызывался только при изменении id

  const handleSelectCategory = useCallback((categoryId) => {
    setSelectedCategory(categoryId);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <CategorySlider 
          categories={categories} 
          onSelectCategory={handleSelectCategory} 
          selectedCategory={selectedCategory} 
        />
        <NewsSection selectedCategory={selectedCategory} />
      </Box>
    </Container>
  );
};

export default CategoriesPage;
