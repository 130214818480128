import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DynamicTimeDisplay from '../DynamicTimeDisplay';

const RelevantItem = ({ story }) => {
  return (
    <Box
      component={Link}
      to={`/story/${story.id}`}
      sx={{
        display: 'block',
        textDecoration: 'none',
        padding: 1,
        marginBottom: 1,
        backgroundColor: 'background.paper',
        borderRadius: '8px',
        '&:hover': {          
          backgroundColor: '#0000000c',
        },
      }}
    >
      <Typography variant="body1" sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'text.primary' }}>
        {story.news_article.title}
      </Typography>
      <Typography variant="body2" sx={{fontSize: '0.2rem', color: 'text.secondary', marginTop: '4px' }}>
        <DynamicTimeDisplay
         creationDate={story.news_article.publication_at}
         sx={{ fontSize: '0.7rem', fontWeight: '600', color: '#999', marginTop: '2px' }}   />
      </Typography>
    </Box>
  );
};

export default RelevantItem;
