import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import {Link} from 'react-router-dom';
import api from '../api';
import NewsCard from './NewsCard';

const NewsSection = ({ type, selectedCategory }) => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {    
    setStories([]);
    setPage(1);
  }, [type, selectedCategory]);

  useEffect(() => {
    const fetchStories = async () => {
      let url = `/api/v1/stories/?page=${page}&page_size=12`;
      
      switch (type) {
        case 'popular':
          url += '&type=popular';
          break;
        case 'current':
          url = `/api/v1/stories/last/?page_size=12`;
          break;
        case 'recommendations':
          url += '&type=recommendations';
          break;
        default:
          break;
      }

      // Фильтрация по категории
      if (selectedCategory) {
        url += `&category=${selectedCategory}`;
      }

      setLoading(true);
      try {
        const response = await api.get(url);
        const formattedStories = response.data.results.map((story) => ({
          id: story.id,
          category: story.category,
          creation_at: story.creation_at,
          articles: {
            id: story.news_article.id,
            title: story.news_article.title,
            description: story.news_article.description,
            summary: story.news_article.summary,
            text: story.news_article.text,
            url: story.news_article.url,
            publication_at: story.news_article.publication_at,
            source: {
              id: story.news_article.source.id,
              name: story.news_article.source.name,
              url: story.news_article.source.url,
              favicon: story.news_article.source.favicon,
            },
            category: {
              id: story.news_article.category.id,
              name: story.news_article.category.name,
            },
            main_image: story.news_article.main_image,
            content_image: story.news_article.content_image,
          },
        }));
  
        setStories((prevStories) => (page === 1 ? formattedStories : [...prevStories, ...formattedStories]));
      } catch (error) {
        console.error('Ошибка при загрузке историй:', error);
      }
      setLoading(false);
    };

    fetchStories();
  }, [type, selectedCategory, page]);


  return (
    <Box>
      <Box
        sx={{
          display: isMobile ? 'flex' : 'block',
          justifyContent: isMobile ? 'center' : 'initial',
          alignItems: isMobile ? 'center' : 'initial',
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        <Typography variant="h4" gutterBottom>
          {type === 'popular' && 'Самые популярные новости'}
          {type === 'current' && 'Текущие новости'}          
        </Typography>
      </Box>
      <Box>
        {loading && page === 1 ? (
          <Grid container spacing={2}>
            {Array.from(new Array(12)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height={320} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {stories.slice(0, 12).map((story) => (
              <React.Fragment key={story.id}>
    <Grid item xs={12} sm={6} md={4} key={story.id}>
      <Link to={`/story/${story.id}`} style={{ textDecoration: 'none' }}>
        <NewsCard item={story.articles} time={story.creation_at} theme={theme} isLink />
      </Link>
    </Grid>
            </React.Fragment>
    ))}
          </Grid>
        )}
        </Box>
            {!loading && stories.length === 0 && (
              <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                Нет новостей для отображения.
              </Typography>
          )}
      </Box>
  );
};

export default NewsSection;
