import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Skeleton, useTheme, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import api from '../api';

const NewsCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {
    api.get('/api/v1/categories/')
      .then(response => {
        setCategories(response.data);
        setLoading(false);
      })
      .catch(error => console.error('Ошибка при загрузке категорий:', error));
  }, []);

  if (isMobile) {
    return null;
  }

  const handleCategoryClick = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  return (
    <Box>
      <Box paddingTop={1} paddingBottom={1}>
        <Typography variant="h4" fontSize={20} gutterBottom>
          Категории новостей
        </Typography>
      </Box>
      {loading ? (
        <Grid container spacing={2} direction="column">
          {Array.from(new Array(14)).map((_, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{ height: 18, padding: 1, borderRadius: 2, boxShadow: 3 }}>
                <CardContent sx={{ padding: '8px !important' }}>
                  <Skeleton variant="text" width="80%" sx={{ marginBottom: 1 }} />
                  <Skeleton variant="text" width="60%" />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={1} direction="column">
          {categories.map((category) => (
            <Grid item xs={12} key={category.id}>
              <Card
                onClick={() => handleCategoryClick(category.id)}
                sx={{
                  borderRadius: 2,
                  boxShadow: 3,
                  overflow: 'hidden',
                  position: 'relative',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)', 
                    zIndex: 2, 
                  },
                  border: `1px solid ${theme.palette.mode === 'dark' ? '#B0B0B0' : 'transparent'}`,
                  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${category.image_url})`,  
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundBlendMode: 'overlay',
                    zIndex: 1,
                    opacity: category.image_url ? 1 : 0,  
                  }}
                />
                <CardContent
                  sx={{
                    padding: '8px !important',
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    alignItems: 'center',  // Выровнять текст по вертикали
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{ fontWeight: 500, fontSize: '1rem', color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}
                  >
                    {category.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default NewsCategories;