import React, { useEffect, useState } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import { getUser } from '../../api';
import ProfileEditForm from './ProfileEditForm';
import NewsSection from '../NewsSection';
import useMediaQuery from '@mui/material/useMediaQuery'; 
import { useTheme } from '@mui/material/styles';
import UserRecommendations from './UserRecommendations';

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await getUser();
        setUser(data);
      } catch (error) {
        console.error('Ошибка получения данных пользователя:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.default, 
        color: theme.palette.text.primary,                 
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={3}>
        <Grid mt={isMobile ? 1 : 7} item xs={12} md={4}>
          {user && <ProfileEditForm user={user} />}
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ mb: 4 }}>                       
            <NewsSection type="popular" />
          </Box>
          <Box sx={{ mb: 4 }}>                        
            <NewsSection type="current" />
          </Box>
          <Box sx={{ mb: 4 }}>            
            <UserRecommendations />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePage;
