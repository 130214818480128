import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Импорт иконок для светлой и тёмной темы
import GoogleIconDark from '../../../img/google.png';
import YandexIconDark from '../../../img/yandex.png';
import VkIconDark from '../../../img/vk.png';
import GoogleIconLight from '../../../img/google-1.png';
import YandexIconLight from '../../../img/yandex-1.png';
import VkIconLight from '../../../img/vk-1.png';

const OAuthButtons = ({ handleOAuthLogin }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const GoogleIcon = isDarkMode ? GoogleIconLight : GoogleIconDark;
  const YandexIcon = isDarkMode ? YandexIconLight : YandexIconDark;
  const VkIcon = isDarkMode ? VkIconLight : VkIconDark;

  const iconStyles = {
    width: 40,
    height: 40,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" align="center" sx={{ fontSize: '12px', mb: 1 }}>
        Или войдите с помощью
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
      <IconButton onClick={() => handleOAuthLogin('google')} sx={{
          ...iconStyles,
          '&:hover img': {
          filter: isDarkMode
            ? 'brightness(0) saturate(100%) invert(75%) sepia(28%) saturate(6482%) hue-rotate(90deg) brightness(107%) contrast(101%)' // Светло-зеленый для темной темы
            : 'brightness(0) saturate(100%) invert(35%) sepia(70%) saturate(6482%) hue-rotate(90deg) brightness(90%) contrast(110%)', // Темно-зеленый для светлой темы
          },
        }}>
          <img src={GoogleIcon} alt="Google" style={iconStyles} />
        </IconButton>
        <IconButton onClick={() => handleOAuthLogin('yandex')} sx={{
          ...iconStyles,
          '&:hover img': {
            filter: isDarkMode
              ?  'brightness(0) saturate(100%) invert(91%) sepia(92%) saturate(2394%) hue-rotate(359deg) brightness(102%) contrast(102%)'
              : 'brightness(0) saturate(100%) invert(57%) sepia(88%) saturate(6000%) hue-rotate(8deg) brightness(101%) contrast(101%)',
          },
        }}>
          <img src={YandexIcon} alt="Yandex" style={iconStyles} />
        </IconButton>
        <IconButton onClick={() => handleOAuthLogin('vk')} sx={{
          ...iconStyles,
          '&:hover img': {
            filter: 'brightness(0) saturate(100%) invert(29%) sepia(95%) saturate(3939%) hue-rotate(206deg) brightness(92%) contrast(85%)', 
          },
        }}>
          <img src={VkIcon} alt="VK" style={iconStyles} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OAuthButtons;
