import React from 'react';
import { Box, Typography } from '@mui/material';
import DropdownShareButton from '../DropdownShareButton';

const NewsFooter = ({ onShareClick, publicationDate }) => (
  <Box
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{ position: 'relative', zIndex: 3 }}
      onClick={onShareClick}
    >
      <DropdownShareButton />
    </Box>
    <Box sx={{ paddingTop: 1, textAlign: 'right', paddingRight: 1 }}>
      <Typography
        variant="caption"
        color="text.secondary"
        fontWeight={'bold'}
        sx={{ alignSelf: 'flex-end' }}
      >
        {publicationDate}
      </Typography>
    </Box>
  </Box>
);

export default NewsFooter;
