import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import api from '../../api';
import RelevantItem from './RelevantItem';

const RelevantSideBar = ({ category }) => {
  const [relatedStories, setRelatedStories] = useState([]);

  useEffect(() => {
    const fetchRelatedStories = async () => {
      try {
        const response = await api.get(`/api/v1/stories/?category=${category}`);
        setRelatedStories(response.data.results.slice(0, 5));  // Ограничиваем до 5 сюжетов
      } catch (error) {
        console.error('Ошибка при загрузке сюжетов:', error);
      }
    };

    if (category) {
      fetchRelatedStories();
    }
  }, [category]);

  return (
    <Box sx={{ width: '100%', paddingTop: 2 }}>
      <Box paddingTop={2} paddingLeft={1}>
        <Typography variant="h6" gutterBottom >
          Новости из категории
        </Typography>
      </Box>
      <Box paddingTop={2}>
        {relatedStories.map((story) => (
          <RelevantItem key={story.id} story={story} />
        ))}
      </Box>
      
    </Box>
  );
};

export default RelevantSideBar;
