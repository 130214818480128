import React from 'react';
import { Box, Grid, Container } from '@mui/material';
import NewsSection from '../components/NewsSection';
import NewsCategories from '../components/NewsCategories';
import useMediaQuery from '@mui/material/useMediaQuery'; 
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <Box sx={{ mb: 4 }}>                
            <NewsSection type="popular" />
          </Box>
          <Box sx={{ mb: 4 }}>                 
            <NewsSection type="current" />
          </Box>
          <Box sx={{ mb: 4 }}>          
            <NewsSection type="recommendations" />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} paddingBottom={5}>
          <Box 
            sx={{ 
              display: isMobile ? 'none' : 'block',              
              top: '20px',
              overflow: 'visible'
            }}
          >
            <NewsCategories />
          </Box>
        </Grid> 
      </Grid>
    </Container>
  );
};

export default HomePage;