import React from 'react';
import DynamicTimeDisplay from '../DynamicTimeDisplay';
import { Box, Typography, Avatar, Card, CardContent } from '@mui/material';

const RelatedArticles = ({ articles, onArticleClick }) => {
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>Эта новость в СМИ:</Typography>
      {articles.map((article, index) => (
        <Card 
          key={index} 
          onClick={() => onArticleClick(article)} 
          sx={{ 
            mb: 2, 
            border: 1, 
            cursor: 'pointer', 
            transition: 'transform 0.2s, background-color 0.2s', 
            '&:hover': { 
              transform: 'scale(1.02)',
              backgroundColor: '#f5f5f5' 
            },
            padding: '10px',
          }}
        >
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={article.source.favicon} alt={`Source ${index + 1} Logo`} sx={{ mr: 2, width: 48, height: 48 }} />
            <Box>
              <Typography variant="body1" fontWeight={700}>{article.source.name}</Typography>
              <Typography variant="body2" sx={{ color: 'gray', marginTop: '4px' }}>
                <DynamicTimeDisplay creationDate={article.publication_at}/>               
              </Typography>
              <Typography variant="body2" sx={{ marginTop: '4px', fontWeight: 'bold' }}>{article.title}</Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default RelatedArticles;
