import React from 'react';
import { Grid, TextField, Button, Box, Typography, Checkbox, CircularProgress, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const RegisterForm = ({ fields, errors, handleChange, handleRegister, checkboxChecked, handleCheckboxChange, pending }) => (
  <Box mt={2} width="100%">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Логин"
          fullWidth
          variant="outlined"
          value={fields.username}
          onChange={handleChange('username')}
          margin="normal"
          error={!!errors.username}
          helperText={errors.username}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={fields.email}
          onChange={handleChange('email')}
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Имя"
          fullWidth
          variant="outlined"
          value={fields.firstName}
          onChange={handleChange('firstName')}
          margin="normal"
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Фамилия"
          fullWidth
          variant="outlined"
          value={fields.lastName}
          onChange={handleChange('lastName')}
          margin="normal"
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Пароль"
          type="password"
          fullWidth
          variant="outlined"
          value={fields.password}
          onChange={handleChange('password')}
          margin="normal"
          error={!!errors.password}
          helperText={errors.password}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Повторите пароль"
          type="password"
          fullWidth
          variant="outlined"
          value={fields.password2}
          onChange={handleChange('password2')}
          margin="normal"
          error={!!errors.password2}
          helperText={errors.password2}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Checkbox checked={checkboxChecked} onChange={handleCheckboxChange} />
          <Typography variant="body2">
            Регистрируясь, вы даете согласие на{' '}
            <Link component={RouterLink} to="/data-processing-policy" underline="always">
              обработку персональных данных
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleRegister}
          color="primary"
          fullWidth
          variant="contained"
          disabled={!checkboxChecked || pending}
        >
          {pending ? <CircularProgress size={24} /> : 'Регистрация'}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default RegisterForm;
