import React from 'react';
import { Box, Grid, Button, useMediaQuery, useTheme  } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LaunchIcon from '@mui/icons-material/Launch';

function addSpaceBeforeLinks(text) {    
    const regex = /(<a\s*href=["'][^>]+>)/g;    
    const updatedText = text.replace(regex, ' $1 ');
    return updatedText;
}


const NewsMainContent = ({ mainImage, text, url }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updatedText = addSpaceBeforeLinks(text);  
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
          
          <Box sx={{ width: '100%', fontSize: 14, fontWeight: 600, textAlign: 'justify' }}>
           
            <span dangerouslySetInnerHTML={{ __html: updatedText }} />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              fontSize: '0.9rem',
              textTransform: 'none',
              padding: '0px 10px',
              borderRadius: '10px',
              fontWeight: '700',
              backgroundColor: '#a8a8a883',
              color: '#000000',
              '&:hover': {
                backgroundColor: '#a8a8a883',
              },
            }}
          >
            Читать в первоисточнике
            <Box ml={1} mt={1} color={'#00000042'}>
              <LaunchIcon sx={{ fontSize: '20px' }} />
            </Box>
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {mainImage && (
          <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'start',
            overflow: 'hidden',
            borderRadius: '10px',            
           
            
          }}
        >
          <img
            src={mainImage}
            alt="Main Story"
            style={{ borderRadius: '8px', border: '1px solid #000000', width: '300px', hight: "200" }}
          />
        </Box>
        )}
      </Grid>


    </Grid>
  </Box>
);
};

export default NewsMainContent;