import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import api from '../api';
import NewsHeader from '../components/NewsDetailPage/NewsHeader';
import NewsMainContent from '../components/NewsDetailPage/NewsMainContent';
import RelatedArticles from '../components/NewsDetailPage/RelatedArticles';
import RelevantSideBar from '../components/RelevantSideBar/RelevantSideBar';

const NewsDetailPage = () => {
  const { id } = useParams();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState(null);
  

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await api.get(`/api/v1/story-detail/${id}/`);
        setStory(response.data);
        setSelectedArticle(response.data.news_articles[0]); 
          
        if (response.data) {
          trackUserActivity(response.data.id); // Передаем id истории
        }
      } catch (error) {
        console.error('Ошибка при загрузке сюжета:', error);
      } finally {
        setLoading(false); // Устанавливаем флаг загрузки вне зависимости от результата
      }
    };
  
    if (id) { // Убедимся, что id существует перед выполнением запроса
      fetchStory();
    }  
  }, [id]);
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!story) {
    return <div>Сюжет не найден</div>;
  }
  
  const trackUserActivity = async (storyId) => {
    try {
      await api.post('/api/v1/user-activity/track_activity/', {

        story_id: storyId,

      });
      console.log('Активность пользователя зафиксирована');
    } catch (error) {
      console.error('Ошибка при фиксации активности пользователя:', error);
    }
  };

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <NewsHeader 
            source={selectedArticle?.source} 
            creationDate={story.creation_at} 
            title={selectedArticle?.title}
          />
          <NewsMainContent           
            mainImage={selectedArticle?.main_image} 
            text={selectedArticle?.summary} 
            url={selectedArticle?.url} 
          />
          <RelatedArticles 
            articles={story.news_articles} 
            onArticleClick={handleArticleClick}  // Передаем функцию для обработки кликов
          />
        </Grid>
        <Grid item xs={12} md={3} paddingTop={2} marginTop={5}>
          <RelevantSideBar category={story.category} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewsDetailPage;
