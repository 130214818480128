// timeAgoUtils.js

export const timeAgo = (date) => {
  const now = new Date();
  const newsDate = new Date(date);
  const diffInMs = now - newsDate;
  const diffInMinutes = Math.floor(diffInMs / 60000); 
  if (diffInMinutes < 1) {
    return 'только что';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${getMinuteLabel(diffInMinutes)} назад`;
  } else if (diffInMinutes < 1440) { // 1440 минут = 24 часа
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} ${getHourLabel(diffInHours)} назад`;
  } else {
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return `${diffInDays} ${getDayLabel(diffInDays)} назад`;
  }
};

// Функции для правильного склонения слов "минута", "час" и "день"
export const getMinuteLabel = (minutes) => {
  if (minutes === 1 || (minutes > 20 && (minutes % 10 === 1))) return 'минуту';
  if ((minutes >= 2 && minutes <= 4) || (minutes > 20 && [2, 3, 4].includes(minutes % 10))) return 'минуты';
  return 'минут';
};

export const getHourLabel = (hours) => {
  if (hours === 1 || (hours > 20 && (hours % 10 === 1))) return 'час';
  if ((hours >= 2 && hours <= 4) || (hours > 20 && [2, 3, 4].includes(hours % 10))) return 'часа';
  return 'часов';
};

export const getDayLabel = (days) => {
  if (days === 1 || (days > 20 && (days % 10 === 1))) return 'день';
  if ((days >= 2 && days <= 4) || (days > 20 && [2, 3, 4].includes(days % 10))) return 'дня';
  return 'дней';
};