import React from 'react';
import { Card } from '@mui/material';
import NewsImage from './NewsCard/NewsImage';
import NewsContent from './NewsCard/NewsContent';
import NewsFooter from './NewsCard/NewsFooter';
import { timeAgo } from '../utils/timeAgoUtils';

const NewsCard = ({ item, time, theme, onShareClick }) => (
  <Card
    sx={{
      height: 320,
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.mode === 'dark' ? '#b0b0b054' : 'transparent'}`,
      mb: 2,
      position: 'relative',  
      paddingBottom: '40px',
    }}
  >
    <NewsImage image={item.main_image} category={item.category} />
    <NewsContent
      title={item.title}
      summary={item.summary}
      description={item.description}
      text={item.text}
    />
    <NewsFooter
      onShareClick={onShareClick}
      publicationDate={timeAgo(time)}
    />
  </Card>
);

export default NewsCard;
