import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Skeleton } from '@mui/material';

const CategorySlider = ({ categories, onSelectCategory, selectedCategory }) => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (categories.length > 0) {
      const urlCategoryId = parseInt(location.pathname.split('/').pop());
      const initialCategoryIndex = categories.findIndex(category => category.id === urlCategoryId);
      if (initialCategoryIndex !== -1) {
        setValue(initialCategoryIndex);
        onSelectCategory(categories[initialCategoryIndex].id);
      }
    }
  }, [categories, location, onSelectCategory]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedCategoryId = categories[newValue].id;
    navigate(`/category/${selectedCategoryId}`);
    onSelectCategory(selectedCategoryId);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
      {categories.length === 0 ? (
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ minHeight: 30 }}
        >
          {Array.from(new Array(5)).map((_, index) => (
            <Tab key={index} label={<Skeleton width={100} />} />
          ))}
        </Tabs>
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          sx={{ minHeight: 30 }}
        >
          {categories.map((category) => (
            <Tab label={category.name} key={category.id} />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default CategorySlider;
