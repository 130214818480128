import React from 'react';
import DynamicTimeDisplay from '../DynamicTimeDisplay';
import { Box, Typography, Avatar, Grid } from '@mui/material';
import { styled } from '@mui/system';

const HeaderContainer = styled(Box)  ({
  padding: '10px',
});

const SourceName = styled(Typography)({
  fontSize: 10,
  fontWeight: 600, 
  paddingTop: 3, 
});

const DateText = styled(Typography)({
  paddingTop: '4px',
  fontSize: '0.775rem',
  fontWeight: 800,
  color: '#adadad',
});

const Title = styled(Typography)(({ theme }) => ({  
  fontSize: '1.6rem',
  fontWeight: 700,
}));

const NewsHeader = ({ source, creationDate, title }) => {
  return (
    <HeaderContainer>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid alignItems="center" item xs={1}>
          <Box paddingTop={0.5} display="flex" flexDirection="column" alignItems="center">
            <Box>
              <Avatar
                src={source.favicon}
                alt="News Source"
                sx={{ width: 35, height: 35 }} />
            </Box>
            <Box>
              <SourceName variant="body1">{source.name}</SourceName>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Box paddingTop={2}>
            <Title variant="h1">{title}</Title>
            <DateText variant="body2">
              <DynamicTimeDisplay  creationDate={creationDate}  sx={{ color: '#adadad', fontWeight: 600, fontSize: '12px' }}/>
            </DateText>
          </Box>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default NewsHeader;
