import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const DataProcessingPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Права субъекта персональных данных
        </Typography>
        <Typography variant="body1" component="div" paragraph>
          Права, механизм реализации прав, а также иная информация, представляемая субъекту персональных данных.
        </Typography>
        <Typography variant="body1" component="div" paragraph>
          Настоящим уведомляем, что организация, которая ответственна за данный сайт (далее — Оператор) в силу реализации положений Закона Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных» перед обработкой персональных данных обязано разъяснить права, связанные с обработкой персональных данных, механизм их реализации, а также предоставить субъекту иную информацию, согласно законодательства.
        </Typography>
        <Typography variant="body1" component="div" paragraph>
          Так, субъект персональных данных имеет право:
        </Typography>
        <ul>
          <li>потребовать от Оператора внести изменения, если персональные данные устарели, неполные или неточные;</li>
          <li>на получение информации, касающейся обработки персональных данных;</li>
          <li>один раз в календарный год бесплатно получать информацию о предоставлении своих данных третьим лицам;</li>
          <li>потребовать прекратить обработку своих данных, их удаление;</li>
          <li>отозвать свое согласие на обработку своих персональных данных;</li>
          <li>обжаловать действия/бездействия и решения Оператора, относящегося к обработке своих персональных данных, в уполномоченный орган по защите прав субъектов персональных данных в порядке, установленном законодательством.</li>
        </ul>
        <Typography variant="body1" component="div" paragraph>
          Для реализации субъектом персональных данных своих прав, необходимо направить заявление Оператору по почте на юридический адрес. Заявление должно содержать:
        </Typography>
        <ul>
          <li>фамилию, имя, отчество (если такое имеется) субъекта персональных данных;</li>
          <li>дату рождения субъекта персональных данных;</li>
          <li>адрес места жительства (места пребывания) субъекта персональных данных;</li>
          <li>номер мобильного телефона и/или электронную почту, указанную при регистрации на сайте;</li>
          <li>суть требований;</li>
          <li>идентификационный номер или номер документа, удостоверяющего личность (при отсутствии идентификационного номера) субъекта персональных данных, если такая информация указывалась субъектом персональных данных при даче согласия или обработка персональных данных осуществляется без согласия субъекта персональных данных;</li>
          <li>личную подпись.</li>
        </ul>
      </Box>
    </Container>
  );
};

export default DataProcessingPolicy;
