import React from 'react';
import { Box, Typography, CardContent } from '@mui/material';

const NewsContent = ({ title, summary, description, text }) => (
  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
    <Box mb={1.5}> 
      <Typography
        variant="h6"
        fontSize={15}
        fontWeight={700}
        gutterBottom
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          lineHeight: '1.2em',
          maxHeight: '2.4em',
        }}
      >
        {title}
      </Typography>
    </Box>
    <Box>
      <Typography
        variant="body2"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 500,
          overflow: 'hidden',
          position: 'relative',
          maxHeight: '6.3em',
          lineHeight: '1.4em',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 4,
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))',
          maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))',
          WebkitMaskSize: '100% 100%',
          maskSize: '100% 100%',
          width: '100%',
          marginBottom: 0,
        }}
      >
        {summary || description || text}
      </Typography>
    </Box>
  </CardContent>
);

export default NewsContent;
