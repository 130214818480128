import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Slide, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Проверяем, было ли дано согласие ранее
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true); // Показываем окно, если согласия нет
    }
  }, []);

  const handleAccept = () => {
    // Сохраняем согласие в localStorage
    localStorage.setItem('cookieConsent', 'true');
    setOpen(false);
  };



  return (
    <>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Затемненный фон
            zIndex: 1300, // Над другими элементами
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end', // Выравнивание по низу
          }}
        >
          <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <Box
              sx={{
                bgcolor: 'background.paper',
                p: 3,
                width: '100%',
                maxWidth: 600,
                boxShadow: 3,
                borderRadius: 1,
                textAlign: 'center',
                zIndex: 1400, // Над затемненным фоном
              }}
            >
              <Typography variant="body1" gutterBottom>
                Обработка файлов cookie
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Наш сайт использует файлы cookie для улучшения пользовательского опыта, сбора статистики и представления персонализированных рекомендаций.
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                Нажав «Принять», вы даете согласие на обработку файлов cookie в соответствии с{' '}
                <Link component={RouterLink} to="/cookie-policy" underline="always">
                  Политикой обработки файлов cookie
                </Link>.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button variant="contained" color="primary" onClick={handleAccept}>
                  Принять
                </Button>                
              </Box>
            </Box>
          </Slide>
        </Box>
      )}
    </>
  );
};

export default CookieConsent;
