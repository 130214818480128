import React, { useState } from 'react';
import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';

const HamburgerMenu = ({ categories }) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();

  // Определение активной категории на основе текущего URL
  const activeCategory = categories.find((category) =>
    location.pathname.includes(`/category/${category.id}`)
  );

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/category/${categoryId}`);
    setOpen(false); // Закрытие меню после выбора категории
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    setOpen(false); // Закрытие меню после выполнения поиска
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem>
          <Box
            component="form"
            onSubmit={handleSearchSubmit}
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
          >
            <SearchIcon />
            <input
              type="text"
              placeholder="Поиск новостей..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ border: 'none', marginLeft: '10px', flex: 1, outline: 'none' }}
            />
          </Box>
        </ListItem>
        {categories.map((category) => (
          <ListItem key={category.id} disablePadding>
            <ListItemButton
              onClick={() => handleCategoryClick(category.id)}
              selected={activeCategory && activeCategory.id === category.id} // Подсветка активной категории
            >
              <ListItemText primary={category.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  if (!isMobile) return null;

  return (
    <div>
      <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default HamburgerMenu;
