import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

// Функция для выбора правильного окончания
const getPluralForm = (n, forms) => {
  return forms[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];
};

// Функция для форматирования времени
const formatTimeAgo = (creationDate) => {
  const now = new Date();
  const date = new Date(creationDate);

  const diffMs = now - date; // Разница во времени в миллисекундах
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffMinutes < 1) return 'только что';
  if (diffMinutes < 60) {
    const minutesForm = getPluralForm(diffMinutes, ['минуту', 'минуты', 'минут']);
    return `${diffMinutes} ${minutesForm} назад`;
  }
  if (diffHours < 24) {
    const hoursForm = getPluralForm(diffHours, ['час', 'часа', 'часов']);
    return `${diffHours} ${hoursForm} назад`;
  }

  if (diffDays === 1) return 'вчера';
  if (diffDays < 7) {
    const daysForm = getPluralForm(diffDays, ['день', 'дня', 'дней']);
    return `${diffDays} ${daysForm} назад`;
  }

  const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear() === now.getFullYear() ? '' : ` ${date.getFullYear()}`;
  
  return `${day} ${month}${year}`;
};

// Компонент с динамическим обновлением времени
const DynamicTimeDisplay = ({ creationDate, sx }) => {
  const [timeAgo, setTimeAgo] = useState(formatTimeAgo(creationDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeAgo(formatTimeAgo(creationDate)); // Обновляем время каждые 60 секунд
    }, 60000);

    // Очищаем таймер при размонтировании компонента
    return () => clearInterval(timer);
  }, [creationDate]);

  return (
    <Box component="span" >
        <Typography variant="body2" component="span" sx={sx}>
            {timeAgo}
        </Typography>
    </Box>    
  );
};

export default DynamicTimeDisplay;
