import React, { useState } from 'react';
import { TextField, Button, Box, CircularProgress, IconButton, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import api from '../../api';

const ProfileEditForm = ({ user }) => {
  const theme = useTheme();
  const [fields, setFields] = useState({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    username: user.username || '',
    avatar: user.avatar || ''
  });
  const [errors, setErrors] = useState({});
  const [pending, setPending] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);


  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFields({ ...fields, avatar: file });
  };

  const handleSave = async () => {
    setPending(true);
    setErrors({});
    setErrorStatus(null);  // Сбрасываем статус ошибки перед новым запросом
  
    const formData = new FormData();
    formData.append('first_name', fields.first_name);
    formData.append('last_name', fields.last_name);    
    formData.append('username', fields.username);
  
    if (fields.avatar instanceof File) {
      formData.append('avatar', fields.avatar);
    }
  
    try {
      await api.patch('/api/auth/user/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Успешное обновление
      setPending(false);
      setShowDialog(true);
      setDialogMessage('Профиль успешно обновлен');
    } catch (error) {
      setPending(false);
      setOpenPopup(true);
  
      if (error.response && error.response.status === 413) {
        setErrorStatus(413);  // Устанавливаем статус ошибки 413 для отображения нужного сообщения
      } else {
        setErrorStatus(null);  // Для всех других ошибок
      }
  
      console.error('Ошибка при обновлении профиля:', error);
    }
  };
  

  const handleCancel = () => {    
    setFields({
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      email: user.email || '',
      username: user.username || '',
      avatar: user.avatar || ''
    });
  };

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box 
      sx={{
        position: 'relative',
        width: isVisible ? '300px' : '0',
        overflow: 'hidden',
        transition: 'width 0.3s ease-in-out',
        height: '100vh',
        
        p: isVisible ? 3 : 0
      }}
    >
      <IconButton 
        sx={{
          position: 'absolute',
          top: '10px',
          right: isVisible ? '-30px' : '-45px', 
          bgcolor: '#fff',
          borderRadius: '50%',
          boxShadow: theme.shadows[2],
          zIndex: 2,
          '&:hover': {
            bgcolor: '#f0f0f0',
          },
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        }}
        onClick={toggleVisibility}
      >
        {isVisible ? <ArrowBackIcon /> : <ArrowForwardIcon />}
      </IconButton>

    {isVisible && (
      <>

    <Box sx={{ p: 3 }}>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <IconButton component="label">
          <Avatar src={fields.avatar instanceof File ? URL.createObjectURL(fields.avatar) : fields.avatar} sx={{ width: 100, height: 100 }} />
          <input type="file" accept="image/*" hidden onChange={handleFileChange} />
        </IconButton>
      </Box>

      <TextField
        label="Имя"
        fullWidth
        variant="outlined"
        value={fields.first_name}
        onChange={handleChange('first_name')}
        margin="normal"
      />

      <TextField
        label="Фамилия"
        fullWidth
        variant="outlined"
        value={fields.last_name}
        onChange={handleChange('last_name')}
        margin="normal"
      />

      <TextField
        label="Email"
        fullWidth
        variant="outlined"
        value={fields.email}
        margin="normal"
        error={!!errors.email}
        helperText={errors.email}
        disabled // Делаем поле неактивным для редактирования
      />


      <TextField
        label="Логин"
        fullWidth
        variant="outlined"
        value={fields.username}
        onChange={handleChange('username')}
        margin="normal"
        error={!!errors.username}
        helperText={errors.username}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={pending}>
          {pending ? <CircularProgress size={24} /> : 'Сохранить'}
        </Button>        
      </Box>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Успех</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            ОК
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>Ошибка</DialogTitle>
        <DialogContent>
          <Typography>
            {errorStatus === 413
              ? 'Файл слишком большой, выберите файл меньше 5 МБ.'
              : 'Произошла ошибка при обновлении профиля. Пожалуйста, попробуйте еще раз.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)} color="primary">
            ОК
          </Button>
        </DialogActions>
      </Dialog>              
    </Box>

    </>
      )}
    </Box>
  );
};

export default ProfileEditForm;
